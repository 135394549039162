import Cookies from 'universal-cookie';

export default function authHeader() {
  const cookies = new Cookies();
  const token = localStorage.getItem("token_cookies");
  if (token) {
    return { Authorization: `Bearer ${token}` };
  } else {
    console.log("not found");
  }
}


