import { ThemeProvider } from "./context/Themes";
import { LoaderProvider } from "./context/Preloader";
import { Suspense, lazy } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  Buttons,
  Charts,
  Tables,
  Fields,
  Headings,
  Colors,
} from "./pages/blocks";
import Protected from "./Protected";

const Admin = lazy(() => import("./pages/master/Admin"));
const Ecommerce = lazy(() => import("./pages/master/Ecommerce"));
const ForgotPassword = lazy(() => import("./pages/master/ForgotPassword"));
const Register = lazy(() => import("./pages/master/Register"));
const Login = lazy(() => import("./pages/master/Login"));
const UserList = lazy(() => import("./pages/master/UserList"));
const UserProfile = lazy(() => import("./pages/master/UserProfile"));
const MyAccount = lazy(() => import("./pages/master/MyAccount"));
const InventoryList = lazy(() => import("./pages/master/InventoryList"));
const ProductView = lazy(() => import("./pages/master/ProductView"));
const ProductUpload = lazy(() => import("./pages/master/ProductUpload"));
const InvoiceList = lazy(() => import("./pages/master/InvoiceList"));
const InvoiceDetails = lazy(() => import("./pages/master/InvoiceDetails"));
const OrderList = lazy(() => import("./pages/master/OrderList"));
const Coupons = lazy(() => import("./pages/master/Coupons"));
const Message = lazy(() => import("./pages/master/Message"));
const Notification = lazy(() => import("./pages/master/Notification"));
const BlankPage = lazy(() => import("./pages/master/BlankPage"));
const OtpVerification = lazy(() => import("./pages/master/OtpVerification"));
const Settings = lazy(() => import("./pages/master/Settings"));
const ResetPassword = lazy(() => import("./pages/master/ResetPassword"));
const Dropshippers = lazy(() => import("./pages/master/Dropshippers"));
const Category = lazy(() => import("./pages/master/Category"));
const SubCategory = lazy(() => import("./pages/master/SubCategory"));
const SellerRegister = lazy(() => import("./pages/master/SellerRegister"));
const VerificationPending = lazy(() =>
  import("./pages/master/VerificationPending")
);
const Reports = lazy(() => import("./pages/master/Reports"));

export default function App() {
  return (
    <ThemeProvider>
      <LoaderProvider>
        <BrowserRouter>
          <Routes>
            {/* master Pages */}
            <Route
              path="/admin"
              element={
                <Suspense fallback={<LoaderProvider />}>
                  <Protected>
                    {" "}
                    <Admin />{" "}
                  </Protected>
                </Suspense>
              }
            />
            <Route
              path="/"
              element={
                <Suspense fallback={<LoaderProvider />}>
                  <Protected>
                    <Ecommerce />{" "}
                  </Protected>
                </Suspense>
              }
            />

            <Route
              path="/login"
              element={
                <Suspense fallback={<LoaderProvider />}>
                  <Login />
                </Suspense>
              }
            />

            {/* <Route path="/analytics" element={<Analytics />} />
            <Route path="/crm" element={<CRM />} /> */}
            {/* <Route path="/register" element={<Register />} /> */}
            <Route
              path="/register"
              element={
                <Suspense fallback={<LoaderProvider />}>
                  <Register />
                </Suspense>
              }
            />
            <Route
              path="/seller-register"
              element={
                <Suspense fallback={<LoaderProvider />}>
                  <SellerRegister />
                </Suspense>
              }
            />
            <Route
              path="/register/otp-verification"
              element={
                <Suspense fallback={<LoaderProvider />}>
                  <OtpVerification />
                </Suspense>
              }
            />
            <Route
              path="/forgot-password"
              element={
                <Suspense fallback={<LoaderProvider />}>
                  <ForgotPassword />
                </Suspense>
              }
            />
            <Route
              path="/reset-password"
              element={
                <Suspense fallback={<LoaderProvider />}>
                  <ResetPassword />
                </Suspense>
              }
            />
            <Route
              path="/user-list"
              element={
                <Suspense fallback={<LoaderProvider />}>
                  <Protected>
                    {" "}
                    <UserList />{" "}
                  </Protected>
                </Suspense>
              }
            />

            <Route
              path="/user-profile"
              element={
                <Suspense fallback={<LoaderProvider />}>
                  <Protected>
                    {" "}
                    <UserProfile />{" "}
                  </Protected>
                </Suspense>
              }
            />
            <Route
              path="/my-account"
              element={
                <Suspense fallback={<LoaderProvider />}>
                  <Protected>
                    {" "}
                    <MyAccount />
                  </Protected>
                </Suspense>
              }
            />
            <Route
              path="/inventory-list"
              element={
                <Suspense fallback={<LoaderProvider />}>
                  <Protected>
                    {" "}
                    <InventoryList />{" "}
                  </Protected>
                </Suspense>
              }
            />
            <Route
              path="/dropshippers"
              element={
                <Suspense fallback={<LoaderProvider />}>
                  <Protected>
                    {" "}
                    <Dropshippers />{" "}
                  </Protected>
                </Suspense>
              }
            />
            <Route
              path="/product-view"
              element={
                <Suspense fallback={<LoaderProvider />}>
                  <Protected>
                    {" "}
                    <ProductView />{" "}
                  </Protected>
                </Suspense>
              }
            />
            <Route
              path="/product-upload"
              element={
                <Protected>
                  {" "}
                  <ProductUpload />{" "}
                </Protected>
              }
            />
            <Route
              path="/invoice-list"
              element={
                <Suspense fallback={<LoaderProvider />}>
                  <InvoiceList />
                </Suspense>
              }
            />
            <Route
              path="/invoice-details"
              element={
                <Suspense fallback={<LoaderProvider />}>
                  <InvoiceDetails />
                </Suspense>
              }
            />
            <Route
              path="reports"
              element={
                <Suspense fallback={<LoaderProvider />}>
                  <Reports />
                </Suspense>
              }
            />
            <Route
              path="/order-list"
              element={
                <Suspense fallback={<LoaderProvider />}>
                  <Protected>
                    {" "}
                    <OrderList />{" "}
                  </Protected>
                </Suspense>
              }
            />
            <Route
              path="/coupons"
              element={
                <Suspense fallback={<LoaderProvider />}>
                  <Protected>
                    {" "}
                    <Coupons />{" "}
                  </Protected>
                </Suspense>
              }
            />
            <Route
              path="/category"
              element={
                <Suspense fallback={<LoaderProvider />}>
                  <Protected>
                    {" "}
                    <Category />{" "}
                  </Protected>
                </Suspense>
              }
            />
            <Route
              path="/subCategory"
              element={
                <Suspense fallback={<LoaderProvider />}>
                  <SubCategory />
                </Suspense>
              }
            />
            <Route path="/message" element={<Message />} />
            <Route path="/notification" element={<Notification />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/blank-page" element={<BlankPage />} />
            <Route
              path="/verification-request"
              element={
                <Suspense fallback={<LoaderProvider />}>
                  <VerificationPending />
                </Suspense>
              }
            />

            {/* Blocks Pages */}
            <Route path="/headings" element={<Headings />} />
            <Route path="/buttons" element={<Buttons />} />
            {/* <Route path="/avatars" element={<Avatars />} /> */}
            <Route path="/colors" element={<Colors />} />
            <Route path="/charts" element={<Charts />} />
            <Route path="/tables" element={<Tables />} />
            <Route path="/fields" element={<Fields />} />
            {/* <Route path="/alerts" element={<Alerts />} /> */}
          </Routes>
        </BrowserRouter>
      </LoaderProvider>
    </ThemeProvider>
  );
}
