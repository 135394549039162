import React from "react";

const TextField = ({
  label,
  value,
  onChange,
  type,
  placeholder,
  multiple,
  checked,
  pattern,
  ...rest
}) => {
  return (
    <>
      <label>{label}</label>
      <input
        type={type}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        multiple={multiple}
        checked={checked}
        pattern={pattern}
        {...rest}
      />
    </>
  );
};

export default TextField;
