import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Table, Thead, Tbody, Th, Tr, Td } from "../elements/Table";
import {
  Anchor,
  Heading,
  Box,
  Text,
  Input,
  Image,
  Icon,
  Button,
} from "../elements";
import { hostedOrderAxios } from "../../backendAxios/backendAxios";
import authHeader from "../../backendAxios/authHeader";
import moment from "moment";
import Lottie from "react-lottie-player";
import animateVector from "../../pages/master/animate__vector.json";

const ApiUrl = "https://api.fazter.com";
// const ApiUrl = "http://localhost:4001";

export default function ProductsTable({ thead, tbody }) {
  const [alertModal, setAlertModal] = useState(false);
  const [orderList, setOrderList] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(tbody);
  }, [tbody]);

  //   const handleCheckbox = (event) => {
  //     const { name, checked } = event.target;

  //     if (name === "allCheck") {
  //       const checkData = data?.map((item) => {
  //         return { ...item, isChecked: checked };
  //       });
  //       setData(checkData);
  //     } else {
  //       const checkData = data?.map((item) =>
  //         item.name === name ? { ...item, isChecked: checked } : item
  //       );
  //       setData(checkData);
  //     }
  //   };

  const handleDownloadInvoice = async (id) => {
    try {
      const response = await fetch(`${ApiUrl}/common/download-pdf/${id}`, {
        method: "GET",
        headers: {
          // Add any required headers here if needed
        },
      });

      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "invoice.pdf";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };
  useEffect(() => {
    getOrderList();
  }, []);

  const getOrderList = async () => {
    await hostedOrderAxios
      .get(`/getallorderbyadmin`, { headers: authHeader() })
      .then((response) => {
        if (response.status === 200) {
          const lastFiveOrderData = response?.data?.order.slice(-5);
          setOrderList(lastFiveOrderData);
        }
      });
  };

  return (
    <Box className="mc-table-responsive">
      <Table className="mc-table product">
        <Thead className="mc-table-head primary">
          <Tr>
            <Th>
              <Box className="mc-table-check">
                {/* <Input
                  type="checkbox"
                  name="allCheck"
                  checked={
                    data?.filter((item) => item.isChecked !== true).length < 1
                  }
                  onChange={handleCheckbox}
                /> */}
                <Text>uid</Text>
              </Box>
            </Th>
            <Th>Order Id</Th>
            <Th>Customer</Th>
            <Th>Products</Th>
            <Th>Amount</Th>
            <Th>Status</Th>
            <Th>Order Date</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>

        {Array.isArray(orderList) && orderList.length === 0 ? (
          <Tbody className="emptyListVector">
            {" "}
            <div className="productTable__content">
              <Lottie
                loop
                animationData={animateVector}
                play
                style={{ width: 350, height: 250 }}
              />
              <h3 className="productTable__maintext">No Recent Orders !</h3>
            </div>
          </Tbody>
        ) : (
          <Tbody className="mc-table-body even">
            {orderList?.map((item, index) => (
              <Tr key={index}>
                <Td title={index + 1}>
                  <Box className="mc-table-check">
                    {/* <Input type="checkbox" name={item.name} /> */}
                    <Text>
                      <Text>{index + 1}</Text>
                    </Text>
                  </Box>
                </Td>
                <td
                // style={{
                //   textTransform: "uppercase",
                //   color: "black",
                //   fontSize: "14px",
                // }}
                >
                  {item._id}
                </td>
                <td>
                  {item?.product_array
                    .map((item) => item.name)
                    .filter(
                      (name, index, array) => array.indexOf(name) === index
                    )}
                </td>
                <td>{`(${item?.count}) Item`}</td>
                <td>{`${item?.product_array
                  ?.map((item) => item.totalPrice)
                  .filter(
                    (totalPrice, index, array) =>
                      array.indexOf(totalPrice) === index
                  )}`}</td>
                <td>
                  {item?.product_array
                    ?.map((item) => item.transaction)
                    .filter(
                      (transaction, index, array) =>
                        array.indexOf(transaction) === index
                    )}
                </td>
                <td>{moment(item?.createdAt).format("LL")}</td>

                <td>
                  <Box className="mc-table-action">
                    <Anchor
                      href={`/invoice-details?_id=${item?._id}`}
                      title="View"
                      className="material-icons view"
                    >
                      <img src="images/View.svg" alt="" />
                    </Anchor>
                    <button onClick={() => handleDownloadInvoice(item?.id)}>
                      <img src="images/download.svg" alt="" />
                    </button>
                  </Box>
                </td>
              </Tr>
            ))}
          </Tbody>
        )}
      </Table>

      <Modal show={alertModal} onHide={() => setAlertModal(false)}>
        <Box className="mc-alert-modal">
          <Icon type="new_releases" />
          <Heading as="h3">are your sure!</Heading>
          <Text as="p">Want to delete this product?</Text>
          <Modal.Footer>
            <Button
              type="button"
              className="btn btn-secondary"
              onClick={() => setAlertModal(false)}
            >
              nop, close
            </Button>
            <Button
              type="button"
              className="btn btn-danger"
              onClick={() => setAlertModal(false)}
            >
              yes, delete
            </Button>
          </Modal.Footer>
        </Box>
      </Modal>
    </Box>
  );
}
