import { Navigate } from "react-router-dom";
// import React, { useState, useEffect } from "react";
// import { hostedCommonModuleAxios } from "./backendAxios/backendAxios";

function Protected({ children }) {
  const isSignedIn = localStorage.getItem("token_cookies");
  if (!isSignedIn) {
    return <Navigate to="/login" />;
  }
  return children;
}
export default Protected;
