import axios from "axios";
import { useLocation } from "react-router-dom";
import { useMemo } from "react";

// const live = process.env.REACT_APP_ApiUrl;
// console.log('live___url',live)

const live = "https://api.fazter.com"
// const live = "http://localhost:4001"

export const hostedSellerAuthAxios = axios.create({
  baseURL: `${live}/seller-auth`,
});
export const hostedSellerProductAxios = axios.create({
  baseURL: `${live}/sellerproduct`,
});

export const hostedAuthAxios = axios.create({
  baseURL: `${live}/auth`,
});
export const hostedCommonModuleAxios = axios.create({
  baseURL: `${live}/common`,
});
export const hostedAddproductAxios = axios.create({
  baseURL: `${live}/sellerProduct`,
});

export const hostedAuthOtpAxios = axios.create({
  baseURL: `${live}/otp`,
});

export const hostedPermissionAxios = axios.create({
  baseURL: `${live}/permission`,
});

export const hostedProductAxios = axios.create({
  baseURL: `${live}/product`,
});

export const hostedCategoryAxios = axios.create({
  baseURL: `${live}/category`,
});

export const hostedCouponAxios = axios.create({
  baseURL: `${live}/coupon`,
});

export const hostedOrderAxios = axios.create({
  baseURL: `${live}/seller-order`,
});
export const hostedCommonModule = axios.create({
  baseURL: `${live}`,
});

export function useQuery() {
  const { search } = useLocation();
  // return new URLSearchParams(search)
  return useMemo(() => new URLSearchParams(search), [search]);
}
